import "core-js/modules/es.array.concat";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import '../image';
import '../styles/app.scss';
import { bindAllResponsiveText } from './lib/responsive-text';
window.addEventListener('DOMContentLoaded', function () {
  bindAllResponsiveText(1920, 16 / 9);
});
document.addEventListener('keydown', function (event) {
  var currentRoute = document.body.dataset.currentRoute;
  var toLeft = event.key === 'ArrowLeft';
  var toRight = event.key === 'ArrowRight';

  if (!currentRoute || !toLeft && !toRight) {
    return;
  }

  event.preventDefault();
  document.location.replace("/page/move/".concat(currentRoute, "/").concat(toLeft ? -1 : 1));
});
document.getElementById('burger') && document.getElementById('burger').addEventListener('click', function (event) {
  document.getElementById('menu-pane').classList.toggle('active');
});