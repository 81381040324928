import './binoculars.svg';
import './bnp_paribas.png';
import './boat.svg';
import './bullet.svg';
import './bullet-sailor.svg';
import './camera.svg';
import './clock.svg';
import './heart-green.svg';
import './heart-red.svg';
import './icon-anchor.svg';
import './icon-binoculars.svg';
import './icon-boat.svg';
import './icon-captain-hat.svg';
import './icon-check.svg';
import './icon-chess.svg';
import './icon-compas.svg';
import './icon-cross.svg';
import './icon-cup.svg';
import './icon-datavern.svg';
import './icon-help.svg';
import './icon-island.svg';
import './icon-less.png';
import './icon-life-buoy.svg';
import './icon-more.png';
import './icon-paper.svg';
import './icon-polaroid.svg';
import './icon-postcard.svg';
import './icon-sailor-hat.svg';
import './icon-score-star.svg';
import './icon-star.svg';
import './icon-ticket.svg';
import './icon-trophy.svg';
import './jane.svg';
import './jane-left.svg';
import './jones.svg';
import './leaf-1.svg';
import './leaf-2.svg';
import './life.svg';
import './life-ko.png';
import './life-ok.png';
import './map-bottom-left.svg';
import './map-bottom-right.svg';
import './map-top-left.svg';
import './map-top-right.svg';
import './menu-anchor.svg';
import './menu-battle.svg';
import './menu-boat.svg';
import './menu-compas.svg';
import './menu-island.svg';
import './name-indata-jones.png';
import './preparation.svg';
import './reed.svg';
import './sailing.svg';
import './temple.svg';
import './winner-1.svg';
import './winner-2.svg';
import './winner-3.svg';