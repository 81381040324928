import "core-js/modules/es.array.for-each";

/**
 * Returns maximum available width depending display ratio.
 */
var getCurrentWidth = function getCurrentWidth(displayRatio) {
  if (window.innerWidth / window.innerHeight > displayRatio) {
    return window.innerHeight * displayRatio;
  }

  return window.innerWidth;
};
/**
 * Makes font size responsive for target using element targetRatio.
 * Should be applied on container. Desired font-size must be defined on children with em.
 */


export var bindResponsiveText = function bindResponsiveText(target, referenceWidth, displayRatio) {
  if (target.classList.contains('bound-responsive-text')) {
    return;
  }

  var computeFontSize = function computeFontSize() {
    var ratio = getCurrentWidth(displayRatio) / referenceWidth;
    target.style.fontSize = "".concat(ratio, "rem");
  };

  computeFontSize();
  setTimeout(computeFontSize, 500);
  window.addEventListener('resize', function () {
    computeFontSize();
  });
  target.classList.add('bound-responsive-text');
};
/**
 * Makes font size responsive for all bindable items in DOM.
 */

export var bindAllResponsiveText = function bindAllResponsiveText(referenceWidth, displayRatio) {
  [].forEach.call(document.querySelectorAll('.responsive-text'), function (item) {
    return bindResponsiveText(item, referenceWidth, displayRatio);
  });
};